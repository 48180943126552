<template>
    <div>
        <div class="misc-wrapper" style="min-height: 40vh;">
            <div class="misc-inner p-2 p-sm-3">
                <div class="w-100 text-center">
                     
                    <div>
                        <img src="@/assets/images/form_done.png" width="400 px"/>
                     </div>

                    <h2 class="mb-1 mt-2">Bedankt, we hebben je gegevens ontvangen.
                    </h2>

                    <p class="mb-2">
                        We nemen contact met je op.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: ["loading"],
    data(){
        return{
            
        }
    }
};
</script>
<style></style>
