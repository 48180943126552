<template>
    <div>
        <div v-if="!loading">
            
            <side-bar v-if="step < 4">
                <template slot="links">
                    <clients-data-wizard-left-sidebar-component :itemData.sync="item_data" />
                </template>
            </side-bar>

            <div class="main-content">
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <b-row>
                         <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                             <div class="mt-3 mb-3 d-flex align-items-center" v-if="step > 0 && step < 4">
                                <b-link v-if="step > 1" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="chevron-left" font-scale="1" class="mr-2"></b-icon>Vorige stap</b-link>
                            </div>

                            <ValidationObserver ref="form">
                                <form @submit.prevent="onSubmit">
                                    <content-step-1 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading_next_step" v-if="step === 1" />
                                    <content-step-2 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading_next_step" :refreshing="refreshing" v-if="step === 2" />
                                    <content-step-3 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading_next_step" v-if="step === 3" />
                                    <content-step-4 :loading="loading_next_step" v-if="step === 4" />
                                </form>
                            </ValidationObserver> 

                            <div v-if="step !== 4 && !loading_next_step" class="mt-4 mb-3" >
                                <app-button @click="saveStep(0)" type="cancel" :disabled="loading">Opslaan</app-button>
                                <app-button @click="nextStep()" :disabled="loading == true" :loading="loading">Doorgaan</app-button>
                            </div>                           

                         </b-col>
                    </b-row>
                </fade-transition>
            </div>

        </div>
        <!-- LOADER HAPPY NURSE -->
        <b-row v-if="loading">
            <app-loader />
        </b-row>
    </div>
</template>
<script>

import AppLoader from '@core/components/AppLoader.vue';
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import { FadeTransition } from 'vue2-transitions';
import ClientsDataWizardLeftSidebarComponent from './ClientsDataWizardLeftSideBarComponent.vue';
import ContentStep1 from './Step1/ClientsDataWizardStep1.vue';
import ContentStep2 from './Step2/ClientsDataWizardStep2.vue';
import ContentStep3 from './Step3/ClientsDataWizardStep3.vue';
import ContentStep4 from './Step4/ClientsDataWizardStep4.vue';

export default {

    components: {
        FadeTransition,
        ValidationObserver,
        AppLoader,
        ClientsDataWizardLeftSidebarComponent,
        ContentStep1,
        ContentStep2,
        ContentStep3,
        ContentStep4,
    },

    props:[],

    created(){
        this.getData();
    },

    data(){
        return{
            loading: false,
            loading_next_step: false,
            step: 1,
            item_data: null,
            show_success_alert: false,
            show_unsuccess_alert: false,
            refreshing: false,
        }
    },

    watch: {
      "item_data.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.refreshing = true;
                    this.getData();
                }                
            },
        },
    },

    methods: {
        getData(){
            this.loading = true;
            
            axios
                .get('clients/data-wizard/getData/' + this.$route.params.token)
                .then((res) => {
                    this.item_data = res.data;
                    this.step = res.data.step;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.refreshing = false;
                });
        },

        saveStep(go_forward){

            if(go_forward == 1){

                if(this.step === 1){// krok pierwszy sprawdzanie poprawnosci adresu i KVK

                    if((this.item_data.address.city === null || this.item_data.address.city == "") && (this.item_data.address.street === null || this.item_data.address.street == "")){
                        return false;
                    }

                    if(this.item_data.kvk.kvk_number === null || this.item_data.kvk.kvk_number.toString().length !== 8 || 
                      this.item_data.kvk.kvk_data.city === null || this.item_data.kvk.kvk_data.city.toString().length === 0 ||
                      this.item_data.kvk.kvk_data.street === null || this.item_data.kvk.kvk_data.street.toString().length === 0 ||
                      this.item_data.kvk.kvk_data.trade_name === null || this.item_data.kvk.kvk_data.trade_name.toString().length === 0){
                        return false;
                    }
                    
                    this.item_data.client_data.invoice_error = 0;
                    
                    if(this.item_data.client_data.cost_place != 1 && this.item_data.client_data.flexworker != 1  && this.item_data.client_data.week != 1){
                        this.item_data.client_data.invoice_error = 1;
                        return false;
                    }
                   
                }
                
                if(this.step === 2) {
                    if(this.item_data.organization_scale.confirm_scale == 0){
                        this.item_data.organization_scale.confirm_scale = null;
                        return false;
                    }
                    this.item_data.client_data.cao_error = 0;
                    if(this.item_data.client_data.id_cao_data_main < 0 || this.item_data.client_data.id_cao_data_main == null){
                        this.item_data.client_data.cao_error = 1;
                        return false;
                    }
                }  

                if(this.step === 3){
                    if(this.item_data.confirm_checklist == 0 || this.item_data.confirm_checklist == null){
                        this.item_data.confirm_checklist = null;
                        return false;
                    }
                }

                
                this.loading_next_step = true;
            }

             this.loading = true;
            
            let form_data = new FormData();

            if(this.step == 1){

                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.id_client_data_main);
                
                axios
                .post("clients/data-wizard/saveStepOne/" + this.$route.params.token,  form_data)
                .then((res) => {
                    this.item_data = res.data;
                    this.step = res.data.step;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;
                    
                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                    
                });



            } else if(this.step == 2){

                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.id_client_data_main);
                
                axios
                .post("clients/data-wizard/saveStepTwo/" + this.$route.params.token,  form_data)
                .then((res) => {
                    this.item_data = res.data;
                    this.step = res.data.step;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_next_step = false;
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;
                    
                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                    
                });

            } else if(this.step == 3){

                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);
                form_data.append("id_client_data_main", this.item_data.id_client_data_main);

                axios
                .post("clients/data-wizard/saveStepThree/" + this.$route.params.token,  form_data)
                .then((res) => {
                    this.item_data = res.data;
                    this.step = res.data.step;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_next_step = false;
                    this.show_unsuccess_alert = !this.show_unsuccess_alert;
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_next_step = false;

                    if(go_forward == 0){
                        this.show_success_alert = !this.show_success_alert;
                    }

                    document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });

                });
            }
            

        },

        nextStep(){
            
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.refs)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                if (!error || !error.value || !error.value.failedRules) return false;
                                return Object.keys(error.value.failedRules).length > 0;
                            });
                        if (errors && errors.length > 0) {
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    }, 100);

                    return false;
                }else{
                    this.saveStep(1);
                }
            });
        },

        goBack(){
            this.loading = true;

            axios
                .get("clients/data-wizard/goBack/" + this.$route.params.token)
                .then((res) => {
                    this.item_data = res.data;
                    this.step = this.item_data.step;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
    
}
</script>
<style>

</style>