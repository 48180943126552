<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">Formulier inlenersbeloning</h1>

            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Succesvol opgeslagen</span>
                </div>
            </b-alert>

            <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
                <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Unsuccesvol opgeslagen</span>
                </div>
            </b-alert>

            <b-overlay :show="loading" :opacity="0.0">

                 <b-card class="mb-3" body-class="pb-1">
                    <b-row class="app-card-header mb-2">
                        <b-col start>
                            <div class="app-card-tittle mb-1">
                                <div> Organisatie </div>    
                            </div>
                        </b-col>
                        <b-col cols="auto" end class="d-flex align-items-center" v-if="refreshing">
                            <div style="min-width:24px; max-width:24px;">
                                <b-spinner class="text-primary" style="width:19px; height:19px; margin-left:2px; margin-top:5px" small></b-spinner>       
                            </div>
                        </b-col>
                    </b-row>

                    <b-alert variant="warning" show class="mb-3 pb-1">
                        <div class="text-md-start mb-2">Volgens de WAADI moeten flexwerkers gelijk beloond worden aan de vaste personeelsleden van de zorginstelling. De gelijke beloning bestaat uit:</div>
                        <ul class="mb-2">
                            <li><div class="text-small mb-1">Brutoloon conform cao schaal,</div></li>
                            <li><div class="text-small mb-1">ATV/ADV,</div></li>
                            <li><div class="text-small mb-1">Toeslagen voor overwerk en onregelmatige uren,</div></li>
                            <li><div class="text-small mb-1">Initiële loonsverhogingen, zoals bij inlener bepaald,</div></li>
                            <li><div class="text-small mb-1">Onkostenvergoedingen,</div></li>
                            <li><div class="text-small">Periodieken</div></li>
                        </ul>
                        <div class="text-md-start mb-2">Om te kunnen voldoen aan deze wet dient de inlener de onderstaande gegevens te valideren.</div>
                    </b-alert> 

                    <b-row >
                        <b-col class="mb-3">
                            <div class="flabel">Selecteer je CAO</div>
                             <div class="filters">
                                <b-button v-for="item in cItemData.organization_scale.cao" :key="item.id_cao_data_main" class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !item.selected, 'app-list-button-selected':  item.selected }" v-on:click="selectOne(item)">
                                    {{item.cao_name}} - {{item.cao_short_name}}
                                </b-button>
                            </div>
                            <small v-if="cItemData.client_data.id_cao_data_main == null && cItemData.client_data.cao_error == 1" class="text-danger">Selecteer minimaal één CAO</small>    
                        </b-col>
                        
                    </b-row>

                    <b-row v-if="cCaoSelectedCount > 1">
                        <b-col cols="12" class="mb-3">
                            <app-row-left-label>
                                <app-radio-group  v-model="cItemData.client_data.id_cao_data_main" :options="cCaoSelectedItems" textField="cao_short_name" valueField="id_cao_data_main" label="Belangrijkste CAO" validatorName="main_role" validatorRules="required" :validatorCustomMessage="{ required: 'Is een verplicht veld' }"/>
                            </app-row-left-label>
                        </b-col>
                    </b-row>

                </b-card>

                <b-tabs class="mb-3" justified v-if="cCaoSelectedCount > 0">
                    <b-tab v-for="item in cCaoSelectedItems" :key="item.id_cao_data_main" :title="item.cao_short_name">
                        <b-card no-body class="app-card-profile">
                            <app-view-table :fields="fields" :items="item.functions" :filters="filters" :customColumns="custom_columns" :isRowHover="false" :isTopBar="false" :isBottomBar="false" :isSettings="false" :isTitle="false" :isDropdownMenu="false" :isSelectable="false" :isMenuColumn="false">
                                <template v-slot:scale_value="item">
                                    <app-select ref="vselect" v-model="item.data.item.scale_value" :options="scale_options" reduceValue  appendToBody :calculatePosition="withPopper"/>
                                </template>
                            </app-view-table>
                        </b-card>
                    </b-tab>
                </b-tabs>

                <b-card class="mb-3">
                    <div class="mt-2">
                        <b-row>
                            <b-col cols="6">
                                <div class="col-list-label app-local-font-size"><b-icon icon="dot" class="app-local-icon-size"/> ATV/ADV:</div>
                                <div class="col-list-label app-local-font-size"><b-icon icon="dot" class="app-local-icon-size"/> Overwerk en ORT:</div>
                                <div class="col-list-label app-local-font-size"><b-icon icon="dot" class="app-local-icon-size"/> Initiele loonsverhoging:</div>
                                <div class="col-list-label d-flex flex-nowrap app-local-font-size"><b-icon icon="dot" class="app-local-icon-size"/> Onkostenvergoeding: reiskosten/slaapdienst:</div>
                                <div class="col-list-label app-local-font-size"><b-icon icon="dot" class="app-local-icon-size"/> Periodieken:</div>
                            </b-col>
                            <b-col cols="6">
                                <div class="col-list-label d-flex justify-content-start app-local-font-size"> niet van toepassing (volgens CAO)</div>
                                <div class="col-list-label d-flex justify-content-start app-local-font-size">volgens CAO</div>
                                <div class="col-list-label d-flex justify-content-start app-local-font-size">volgens CAO</div>
                                <div class="col-list-label d-flex justify-content-start app-local-font-size">volgens CAO</div>
                                <div class="col-list-label d-flex justify-content-start app-local-font-size">volgens CAO</div>
                            </b-col>
                        </b-row>                    

                        <app-row-left-label :slots="[6,6]" class="mt-2" :isMarginBetween="false">
                            <template v-slot:col-1>
                                <app-input v-model="cItemData.organization_scale.completed_name" label="Formulier ingevuld door" validatorRules="required" validatorName="Formulier ingevuld door" mask="lettersE8-30" />
                            </template>
                            <template v-slot:col-2>
                                <app-input v-model="cItemData.organization_scale.completed_function" label="Functie" validatorRules="required" validatorName="Functie" mask="lettersE4-30" />
                            </template>
                        </app-row-left-label>

                        <app-row-left-label class="mt-2">
                            <app-check-box v-model="cItemData.organization_scale.confirm_scale" rightLabel="De bovenstaande beloningsstructuur is naar waarheid ingevuld." validatorRules="required" validatorName="Toestemming" :validatorCustomMessage="{ required: 'Toestemming is verplicht' }" />
                        </app-row-left-label> 
                    </div>
                </b-card>
                

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
                
    </div>
</template>

<script>

import axios from "@axios";
import AppRowLeftLabel from '@core/components/AppRowLeftLabel.vue';
import AppLoader from '@core/components/AppLoader.vue';
import { createPopper } from '@popperjs/core';

export default {
    components: {
        AppRowLeftLabel,
        AppLoader,
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading", "refreshing"],
        
    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            custom_columns: ["scale_value"],
            fields: [
                { key: "function_name", label: "Functie", sortable: true, thStyle: { "min-width": "80px", "max-width": "80px" },visible: true},
                { key: "scale_value", label: "Schaal", sortable: false, thStyle: { "min-width": "50px", "max-width": "50px"}, tdClass: "app-local-td-class",  visible: true }
            ],
            filters: {},
            scale_options: [
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 15, name: "15" },
                { value: 20, name: "20" },
                { value: 25, name: "25" },
                { value: 30, name: "30" },
                { value: 35, name: "35" },
                { value: 40, name: "40" },
                { value: 45, name: "45" },
                { value: 50, name: "50" },
                { value: 55, name: "55" },
                { value: 60, name: "60" },
            ],
        }
    },

    mounted(){
        this.sortFunctions();
    },


    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) {this.$emit('update:itemData', value) }, 
        },

        cCaoSelectedCount : {
            get() { 
                    if(this.cItemData.organization_scale.cao != null){
                        return this.cItemData.organization_scale.cao.filter(({selected}) => selected == 1).length;
                    }else{
                        return 0;
                    }
                },
        },

        cCaoSelectedItems: {
            get() { 
                    if(this.cItemData.organization_scale.cao != null){
                        return this.cItemData.organization_scale.cao.filter(({selected}) => selected == 1).sort(function(a, b) {return a.sort_index > b.sort_index});
                    }else{
                        return null;
                    }
                },
        },
    },

    watch:{
        showSuccessAlert(val){
           this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "cItemData.organization_scale.confirm_scale"(){
            if(this.cItemData.organization_scale.confirm_scale == 0){
                this.cItemData.organization_scale.confirm_scale = null;
            }
        },
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        selectOne(item){
            
            if(item.selected == 1){
                item.selected = 0;
            }else{
                item.selected = 1;
            }

            if(this.cItemData.client_data.id_cao_data_main == null && this.cCaoSelectedCount > 0){
                this.cItemData.client_data.id_cao_data_main = this.cCaoSelectedItems[0].id_cao_data_main;
            }else if(this.cCaoSelectedCount == 0){
                 this.cItemData.client_data.id_cao_data_main = null;
            }else if(this.cItemData.client_data.id_cao_data_main != null && this.cCaoSelectedCount > 0 && this.cItemData.client_data.id_cao_data_main != this.cCaoSelectedItems[0].id_cao_data_main){
                this.cItemData.client_data.id_cao_data_main = this.cCaoSelectedItems[0].id_cao_data_main;
            }
            this.sortFunctions(item);
        },

        sortFunctions(item = null){

            if(item){
                item.functions.list.sort((a,b) => (a.scale_value === b.scale_value) ? (a.function_name.toLowerCase() < b.function_name.toLowerCase() ? -1 : 1) : (a.scale_value < b.scale_value) ? -1:1);
            } else{
                this.cCaoSelectedItems.forEach(element => {
                    element.functions.list.sort((a,b) => (a.scale_value === b.scale_value) ? (a.function_name.toLowerCase() < b.function_name.toLowerCase() ? -1 : 1) : (a.scale_value < b.scale_value) ? -1:1);
                });
            }
        },

        withPopper(dropdownList, component, { width }){

            
            var width_decimals = parseFloat(width) - Math.floor(parseFloat(width));
            var new_width = 0;
            
            if(component.$el.offsetWidth == parseInt(width)){
                new_width = component.$el.offsetWidth + width_decimals + 'px';
            }else if(component.$el.offsetWidth > parseInt(width)){
                 new_width = component.$el.offsetWidth + '.0px';
            }else if(component.$el.offsetWidth < parseInt(width)){
                new_width = component.$el.offsetWidth +'.5px';
            }

            dropdownList.style.width = new_width

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                        component.$el.classList.toggle(
                            'drop-up',
                            state.placement === 'top'
                        )
                        },
                    },
                
                ],
            })

            return () => popper.destroy()
        },
    }
};
</script>
<style>
.app-local-font-size{
    font-size: 14px;
}

.app-local-icon-size{
    min-width:20px; 
    min-height:20px;    
}

.app-local-td-class {
    max-width: 60px;
    min-width: 60px;
}

.app-local-label{
    font-size: 13px;
    color: #174066;
    margin-left: 2px;
}

.app-local-spinner{
    width:19px; 
    height:19px; 
    margin-top: 29px;
}

.vs__selected-options{
    overflow: hidden;
}
.app-local-btn{
    margin-top: 10px;
}
.app-local-td-class {
    max-width: 60px;
    min-width: 60px;
}
</style>
