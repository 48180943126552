<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">Organisatie <span>informatie</span></h1>

            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                <div>
                        <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                        <span class="align-middle">Succesvol opgeslagen</span>
                </div>
                
            </b-alert>

            <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
                <div>
                        <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                        <span class="align-middle">Unsuccesvol opgeslagen</span>
                </div>
                
            </b-alert>

            <b-overlay :show="loading" :opacity="0.0">

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">Algemene gegevens</div>
                    <clients-data-wizard-organization-general-component :kvk.sync="cItemData.kvk" :clientName.sync="cItemData.client_data.client_name" />
                </b-card>

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">Adresgegevens</div>
                    <app-wizard-address-nl :isMarginBetween="false" :address="cItemData.address"/>
                </b-card>

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">De financiële gegevens</div>

                    <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <!-- <app-input v-model="cItemData.client_data.btw_number" validatorName="BTW nummer" label="BTW nummer" validatorRules="required|btw-number" mask="BTW" :uppercase="true" /> -->
                            <app-input v-model="cItemData.client_data.btw_number" validatorName="BTW nummer" label="BTW nummer" validatorRules="required|btw-number" mask="BTW" :uppercase="true" />
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">Organisatie vrijgesteld van BTW?</label>
                            <validation-provider v-slot="{ errors}" vid="vat_calculation" ref="myinput" rules="required" name="Vrijgesteld van BTW" :custom-messages="{ required: 'Het veld is verplicht' }">
                                <app-select v-model="cItemData.client_data.vat_calculation" reduceValue :options="btw_radio" />
                                <small class="text-danger">{{errors[0]}}</small> 
                            </validation-provider>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">Uren op factuur per</label>
                            <app-select v-model="cItemData.client_data.hours_on_invoice" reduceValue :options="invoice_hours_options" />
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">Factuur frequentie</label>
                            <validation-provider v-slot="{ errors}" vid="frequency_type" ref="myinput" rules="required" name="Factuur frequentie" :custom-messages="{ required: 'Het veld is verplicht' }">
                                <app-select v-model="cItemData.client_data.id_invoice_frequency_const_type" reduceValue :options="frequency" />
                                <small class="text-danger">{{errors[0]}}</small> 
                            </validation-provider>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <app-email :email.sync="cItemData.client_data.invoice_email" validatorName="Factuur email adres" validatorRules="required" label="Factuur email adres" type="client" customSpinnerClass="app-local-input-spinner"/>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">Factuur splitsen per</label>
                            <b-row class="m-1">
                                <app-check-box id="ch_cost_place" v-model="cItemData.client_data.cost_place" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" rightLabel="Kostenplaats" />
                                <app-check-box id="ch_flexworker" v-model="cItemData.client_data.flexworker" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" rightLabel="Flexwerker" />
                                <app-check-box id="ch_week" v-model="cItemData.client_data.week" class="custom-control-primary" value="1" unchecked-value="0" size="lg" rightLabel="Week" />
                            </b-row>
                            <small class="text-danger" v-if="cItemData.client_data.invoice_error == 1">Het veld is verplicht</small>
                        </template>
                    </app-row-left-label>
                    <div class="mb-3">
                        <small class="text-muted"> HappyNurse hanteert een standaard betaaltermijn van 14 dagen.</small>
                    </div>

                </b-card>
                

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>

                <!--additionalInfo="HappyNurse hanteert een standaard betaaltermijn van 14 dagen."-->
                <!--additionalInfo="Landcode NL, 9 cijfers, letter B en een controle getal van 2 cijfers."-->
        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
    </div>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import { btwnumber } from "@validations";
import AppLoader from '@core/components/AppLoader.vue';
import AppWizardAddressNl from '@core/components/Address/AppWizardAddressNl.vue';
import AppEmail from '@core/components/AppEmail.vue';
import ClientsDataWizardOrganizationGeneralComponent from './Components/ClientsDataWizardOrganizationGeneralComponent.vue';


export default {
    components: {
        AppWizardAddressNl,
        AppLoader,
        AppEmail,
        ClientsDataWizardOrganizationGeneralComponent
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],
    

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "itemData": {
            handler: function(val) {
                if(this.cItemData.client_data.cost_place != 1 && this.cItemData.client_data.flexworker != 1  && this.cItemData.client_data.week != 1){
                    this.cItemData.client_data.invoice_error = 1;
                }else{
                    this.cItemData.client_data.invoice_error = 0;
                }             
            },
            deep: true
        }
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,

            btw_radio: [
                { value: 1, name: "Ja" },
                { value: 0, name: "Nee" },
                { value: 2, name: "Afhankelijk van de dienst" },
            ],

            frequency: [
                { value: 1, name: "Wekelijks" },
                { value: 2, name: "Per 4 weken" },
                { value: 3, name: "Maandelijks" },
            ],

            invoice_hours_options: [
                { value: 0, name: 'Dag' },
                { value: 1, name: 'Declaratie' },
                { value: 2, name: 'Week' }
            ],
        }
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    }
};
</script>
<style></style>
<style lang="scss">
</style>
