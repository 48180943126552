<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">Arbo <span>checklist</span></h1>

            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Succesvol opgeslagen</span>
                </div>
                
            </b-alert>

            <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
                <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">Unsuccesvol opgeslagen</span>
                </div>
                
            </b-alert>

            <b-overlay :show="loading" :opacity="0.0">

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">Veiligheid en gezondheid medisch personeel</div>

                    <!-- check 1 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexwerker moet voor de werkzaamheden waar hij/zij mee te maken krijgt in bezit zijn van een geldige BIG-registratie.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_1" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 2 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexwerker krijgt te maken met blootstelling aan biologische agentia, zoals bacteriën en virussen, via contact met besmette patiënten of via besmet bloed.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_2" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!--check 3 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexkracht gaat werkzaamheden verrichten waarbij vaccinatie tegen hepatitis B noodzakelijk is.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_3" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 4 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexkracht ontvangt de benodige vaccinatie van de opdrachtgever.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_4" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 5 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexwerker kan tijdens het werk worden blootgesteld aan cytostatica of narcosegassen.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_5" :options="answers"  validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 6 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">De flexkracht krijgt te maken met momenten waarop hij/zij alleen werkt.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_6" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 7 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">Zijn er andere risico's waar de flexkracht mee te maken krijgt en die niet genoemd zijn.</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_7" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: 'Het veld is verplicht' }" />
                        </template>
                    </app-row-left-label>

                    <!-- TextArea for check 7 -->
                    <app-row-left-label :slots="[3, 9]" :isMarginBetween="true" class="mt-2" v-if="itemData.question_7 == 1">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">Beschrijving van deze risico's</label>
                        </template>
                        <template v-slot:col-2>
                            <app-memo id="textarea-default" rows="5" v-model="cItemData.risk_description"/>  
                        </template>
                    </app-row-left-label>
                </b-card>

                <b-card class="mb-3">

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.completed" label="Arbo checklist ingevuld door" mask="lettersE8-30" validatorRules="required" validatorName="Arbo checklist ingevuld door" />
                        </template>

                        <template v-slot:col-2>
                            <app-input v-model="cItemData.functions" label="Functie" validatorRules="required" validatorName="Functie" mask="lettersE4-30" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label class="mt-2">
                        <app-check-box v-model="cItemData.confirm_checklist" rightLabel="De ARBO checklist is naar waarheid ingevuld" validatorRules="required" validatorName="Toestemming" :validatorCustomMessage="{ required: 'Toestemming is verplicht' }" />
                    </app-row-left-label> 

                </b-card>

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
                
    </div>
</template>

<script>

import AppLoader from '@core/components/AppLoader.vue';

export default {
    components: {
        AppLoader
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],
        
    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            answers: [
                { value: 1, text: "Ja" },
                { value: 0, text: "Nee" },
            ],
        }
    },

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "cItemData.question_7": {
            handler: function(val) {
                if (val == 0) {
                    this.cItemData.risk_description = '';
                }
            },
        },
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    },
    
    computed: {
        cItemData: {
            get() { return this.itemData},
            set() {}
        }
    }
};
</script>
<style scoped>

</style>

