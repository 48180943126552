<template> 
    <ValidationObserver ref="observer">
        <b-form>
            <b-row class="display-flex align-items-end">

                <b-col md="6" xl="6">
                    <app-row-left-label>
                        <app-input v-model="cClientName" validatorName="Organisatienaam" label="Organisatienaam" validatorRules="required" mask="lettersE4-60"/>
                    </app-row-left-label>
                    <app-row-left-label>
                        <app-input v-model="cKvk.kvk_number" validatorName="KVK nummer" label="Vul hier je KVK nummer in" validatorRules="required|digits:8" mask="########" v-on:blur="checkKvk()" />
                    </app-row-left-label>
                </b-col>

                <b-col md="6" xl="6">
                    
                    <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-info">
                            <div class="app-info-card-label" v-if="loading">
                                <b-spinner class="app-local-spinner"/>Controleren..
                            </div>
                            <div class="app-info-card-label" v-if="!loading && status === 1">
                                <label class="flabel">KVK gegevens</label>
                                <div> {{ checked_kvk_trade_name }} </div>
                                <div> {{ checked_kvk_info }} </div>
                            </div>
                        </b-card>
                    </app-row-left-label>

                    <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">Verkeerd nummer</label>
                            </div>
                        </b-card>
                    </app-row-left-label>

                </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>
</template>

<script>
import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: {
        kvk: {},
        clientName:{},
        required: { type: Boolean, default: false }
    },

    created() {
        
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.toString().length == 8 &&
           this.kvk.kvk_data.city != null && this.kvk.kvk_data.city.toString().length > 0 && 
           this.kvk.kvk_data.street != null && this.kvk.kvk_data.street.toString().length > 0 &&
           this.kvk.kvk_data.trade_name != null && this.kvk.kvk_data.trade_name.toString().length > 0){
            this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
            this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;
            this.status = 1;
        }
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.length != 0 && this.kvk.kvk_data.city == "" && this.kvk.kvk_data.street == "" && this.kvk.kvk_data.trade_name == ""){
            this.status = 2;
        }
        
    },

    computed: {
        cClientName:{
            get() { return this.clientName },
            set(value) { this.$emit('update:clientName', value) }
        },
        cKvk:{
            get() { return this.kvk },
            set(value) { this.$emit('update:kvk', value) }
        },
    },
    
    data() {
        return {
            loading: false,
            status: 0,   
            checked_kvk_info: "",
            checked_kvk_trade_name: ""
        };
    },

    methods: {
        checkKvk(){
            
            if(this.kvk.kvk_number === null || this.kvk.kvk_number === undefined || this.kvk.kvk_number.toString().length !== 8){
                this.status = 0;
                this.checked_kvk_info = "";
                this.checked_kvk_trade_name = "";
                this.cKvk.kvk_data.kvk_number = "";
                this.cKvk.kvk_data.city = "";
                this.cKvk.kvk_data.street = "";
                this.cKvk.kvk_data.trade_name = "";
                
                return;
            }

            this.loading = true;
            this.cKvk.kvk_data.kvk_number = "";
            this.cKvk.kvk_data.city = "";
            this.cKvk.kvk_data.street = "";
            this.cKvk.kvk_data.trade_name = "";

            this.$refs.observer.validate();
            
            axios
                .get("core/kvk/check/" + this.kvk.kvk_number)     
                .then((response) => {
                    
                    if(response.data == -1){
                        this.checked_kvk_info = "";
                        this.checked_kvk_trade_name = "";
                        return this.status = 2;
                    }

                    this.cKvk.kvk_number = response.data.kvk_number;
                    this.cKvk.kvk_data.kvk_number = response.data.kvk_number;
                    this.cKvk.kvk_data.city = response.data.city;
                    this.cKvk.kvk_data.street = response.data.street;
                    this.cKvk.kvk_data.trade_name = response.data.trade_name;
                    this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
                    this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;

                    this.status = 1;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });

        }


    },
};
</script>

<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem !important;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
}
</style>
